
import css from "./404.module.scss";
const Page404Component = (props) => {
    
    const onClickLink = (e) => {
        props?.navigateToPage("home");
    };

    return (
        <>
            <div className={css.wrapper}>
                <div className={css.box}>
                    <div className={css.h1}>404</div>
                    <div>
                        <a onClick={onClickLink}>Return to the Home Page</a>.
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default Page404Component;